import prestashop from 'prestashop';
import { createComponent } from '@/helpers/alpine';

function toggleBodyOverflow(force: boolean) {
    document.body.classList.toggle('md:overflow-y-hidden', force);
}

class Accessory {
    controller!: string;
    id_product!: string | number;
    id_combination!: string | number;
    id_product_accessorie!: string | number;
    image: { [key: number]: string; cover?: string } = {};
    name!: string;
    price!: string;

    constructor(args: Partial<Accessory>) {
        Object.assign(this, args);
    }

    get hasImage() {
        return Object.values(this.image).length;
    }

    get firstImage() {
        return Object.values(this.image)
            .filter((i) => i.length)
            .shift();
    }

    get cover() {
        return this.image.cover;
    }
}

export default createComponent(() => ({
    isOpened: false,
    action: undefined as string | undefined,
    accessories: [] as Accessory[],
    previewedAccessory: null as Accessory | null,
    selectedAccessory: null as Accessory | null,
    selectedAccessories: [] as Accessory[],
    multiple: false,

    preview(accessory: Accessory) {
        if (this.multiple) {
            this.addOrRemove(accessory);
        } else this.previewedAccessory = accessory;
    },

    addOrRemove(accessory: Accessory) {
        if (this.selectedAccessories.includes(accessory)) {
            const selectedAccessories = [] as Accessory[];
            if (this.selectedAccessories.length > 1) {
                this.selectedAccessories.forEach(
                    (selectedAccessory: Accessory) => {
                        if (accessory != selectedAccessory) {
                            selectedAccessories.push(selectedAccessory);
                        }
                    },
                );
            }
            this.selectedAccessories = selectedAccessories;
        } else {
            this.selectedAccessories.push(accessory);
        }
        this.previewedAccessory = accessory;
    },

    removeTrailingZeros(price: string) {
        const cleanPrice = price.trim();
        if (cleanPrice.includes(',00')) {
            return cleanPrice.replace(',00', '');
        }
        return cleanPrice;
    },

    open() {
        this.isOpened = true;
    },

    close() {
        this.isOpened = false;
        this.previewedAccessory = null;
    },

    async query(action: string, data: object) {
        const url = new URL(action);
        Object.entries(data).forEach(([k, v]) => url.searchParams.append(k, v));

        return fetch(url);
    },

    async select() {
        if (this.multiple) {
            const id_product_accessories = [] as string[];
            let id_product = '';
            let controller = '';

            this.selectedAccessories.forEach((selectedAccessory: Accessory) => {
                controller = selectedAccessory.controller;
                id_product = '' + selectedAccessory.id_product;
                id_product_accessories.push(
                    '' + selectedAccessory.id_product_accessorie,
                );
            });

            await this.query(controller, {
                addaccessories: 1,
                id_product: id_product,
                id_product_accessories: id_product_accessories,
            });
        } else {
            this.selectedAccessory = this.previewedAccessory;
            const {
                controller,
                id_product,
                id_product_accessorie,
                id_combination,
            } = this.selectedAccessory as Accessory;

            await this.query(controller, {
                addaccessory: 1,
                id_product,
                id_accessoire_product: id_product_accessorie,
                id_accessoire_combination: id_combination,
            });
        }

        this.close();
    },

    async remove(accessory: Accessory) {
        const {
            controller,
            id_product,
            id_product_accessorie: id_accessory,
            id_combination: id_combination,
        } = accessory;

        await this.query(controller, {
            removeaccessory: 1,
            id_product,
            id_accessory,
            id_combination,
        });

        if (this.multiple) {
            this.addOrRemove(accessory);
        } else {
            this.selectedAccessory = null;
        }
    },

    async getAccessories() {
        if (this.action) {
            const response = await fetch(this.action, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });

            const data = (await response.json()) as Partial<typeof Accessory>;
            this.accessories = Object.values(data).map((d) => new Accessory(d));
        }
    },

    setup(action: string, multiple: boolean) {
        this.action = action;
        this.multiple = multiple;

        this.getAccessories();
        prestashop.on('updatedProduct', () => this.getAccessories());

        toggleBodyOverflow(this.isOpened);
        this.$watch('isOpened', toggleBodyOverflow);

        this.$watch('selectedAccessory', () =>
            this.$dispatch('accessory:update', this.selectedAccessory),
        );
        this.$watch('selectedAccessories', () =>
            this.$dispatch('accessories:update', this.selectedAccessories),
        );
        this.$watch('previewedAccessory', () => {
            // force le rechargement de l'image
            const preview = document.querySelector(
                '.js-preview-accessory',
            ) as HTMLImageElement;
            if (preview) {
                preview.classList.toggle('entered');
                preview.classList.toggle('loaded');
                preview.removeAttribute('data-ll-status');
            }
        });
    },
}));
