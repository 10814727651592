import { YoutubeIframeAPI } from '@/services/youtube';
import { createComponent } from '@/helpers/alpine';

type TVideoPlayerYoutubeOps = {
    videoId?: string;
    autoplay?: boolean;
    controls?: boolean;
};

const VideoPlayerYoutube = createComponent((opts: TVideoPlayerYoutubeOps) => ({
    videoId: opts.videoId,
    controls: opts.controls ?? true,
    autoplay: opts.autoplay ?? false,
    player: null as null | YT.Player,
    isPlaying: false,

    get defaultCover() {
        return `https://img.youtube.com/vi/${this.videoId}/hqdefault.jpg`;
    },

    async setup() {
        if (!this.videoId) {
            return;
        }

        await YoutubeIframeAPI.ready;
        this.player = new YT.Player(this.$refs.placeholder, {
            videoId: this.videoId,
            width: '100%',
            height: '100%',
            host: 'https://www.youtube-nocookie.com',
            playerVars: {
                controls: this.controls ? 1 : 0,
                autoplay: this.autoplay ? 1 : 0,
                mute: this.autoplay ? 1 : 0,
                showinfo: 0,
                iv_load_policy: 3,
                cc_load_policy: 0,
                modestbranding: 0,
                enablejsapi: 1,
                origin: window.location.host,
            },
        });

        this.player.addEventListener('onStateChange', () => {
            if (this.player?.getPlayerState() == YT.PlayerState.PLAYING) {
                this.isPlaying = true;
            }
        });
    },

    async play() {
        this.player?.playVideo();
    },

    init() {
        YoutubeIframeAPI.load();
    },
}));

export { VideoPlayerYoutube };
