import LazyLoad from 'vanilla-lazyload';

const lazyLoadInstance = new LazyLoad({
    elements_selector: '.js-lazy',
    threshold: 0,
});

const lazyLoadEarlyInstance = new LazyLoad({
    elements_selector: '.js-lazy-threshold',
    threshold: 600,
});

const DOMobserver = new MutationObserver(() => {
    lazyLoadInstance.update();
    lazyLoadEarlyInstance.update();
});

if (document.readyState !== 'loading') {
    DOMobserver.observe(document, { childList: true, subtree: true });
} else {
    document.addEventListener('DOMContentLoaded', () => {
        DOMobserver.observe(document, { childList: true, subtree: true });
    });
}
