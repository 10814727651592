function onReady(func: EventListener): void {
    if (document.readyState !== 'loading') {
        return func(new Event('ready'));
    }

    document.addEventListener('DOMContentLoaded', func);
}

function onLoad(func: (event?: Event) => void) {
    if (document.readyState === 'complete') {
        return func();
    }

    window.addEventListener('load', func);
}

function getChildIndex(parent: HTMLElement, child: HTMLElement) {
    return Array.from(parent.children).indexOf(child);
}

function getChildAt<T extends HTMLElement>(parent: HTMLElement, index: number) {
    return parent.children[index] as T;
}

function getChildCount(parent: HTMLElement) {
    return Array.from(parent.children).length;
}

function createNode<T extends HTMLElement>(htmlString: string) {
    const el = document.createElement('div');
    el.innerHTML = htmlString;

    return el.firstElementChild as T;
}

function cloneAttributes(el: HTMLElement, targetEl: HTMLElement) {
    const attributes = Array.from(el.attributes);

    let attr;
    while ((attr = attributes.pop())) {
        if (attr.nodeValue == null) {
            continue;
        }

        targetEl.setAttribute(attr.nodeName, attr.nodeValue);
    }
}

export {
    onReady,
    onLoad,
    getChildIndex,
    getChildAt,
    getChildCount,
    createNode,
    cloneAttributes,
};
