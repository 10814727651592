import prestashop from 'prestashop';
import { createComponent } from '@/helpers/alpine';
import { Helpers } from '@/helpers';

type TEventReason = {
    reason?: {
        idProduct: string;
        idProductAttribute: string;
        idCustomization: string;
        linkAction: string;
    };
};

type TPsShoppingCartOpts = {
    url?: string;
};

export default createComponent((opts: TPsShoppingCartOpts) => ({
    boundEventHandler: (() => {}) as (event: TEventReason) => void,
    controller: new AbortController(),
    url: opts.url ?? '',

    async handleUpdateCart(event: TEventReason) {
        if (event.reason == undefined) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.controller ? this.controller.abort() : null;
        this.controller = new AbortController();
        const { signal } = this.controller;

        const {
            linkAction: action,
            idProduct: id_product,
            idProductAttribute: id_product_attribute,
            idCustomization: id_customization,
        } = event.reason;

        const requestData = {
            id_product_attribute,
            id_product,
            id_customization,
            action,
        };

        const req: RequestInit = {
            body: Helpers.Object.toFormData(requestData),
            signal,
            method: 'POST',
            headers: {
                Accept: 'application/json',
            },
        };

        const url = new URL(this.url, window.location.origin);
        const response = await fetch(url.toString(), req);
        const data = await response.json();

        if (data.modal) {
            document.body.insertAdjacentHTML('beforeend', data.modal);

            await this.$nextTick();
            this.$dispatch('cart-update-modal');
        }

        if (data.preview) {
            this.$el.outerHTML = data.preview;
        }
    },

    destroy() {
        prestashop.off('updateCart', this.boundEventHandler);
    },

    init() {
        this.boundEventHandler = (e) => this.handleUpdateCart.bind(this)(e);
        prestashop.on('updateCart', this.boundEventHandler);
    },
}));
