import { createComponent } from '@/helpers/alpine';
import { throttle } from 'lodash-es';

const Header = createComponent(() => ({
    threshold: 10,
    isAffixed: false,

    desktopHoveredMenu: null as string | null,
    mobileClickedMenu: null as string | null,
    isBurgerMenuOpen: false as boolean,
    checkWindowSizeTimer: 0 as number,
    isMobile: false as boolean,

    setDesktopHoveredMenu(menuId: string | null) {
        if (menuId && !this.isMobile) {
            this.desktopHoveredMenu = menuId;
        } else {
            this.desktopHoveredMenu = null;
        }
    },

    setMobileClickedMenu(menuId: string | null, event: Event | null) {
        if (event && this.isMobile) {
            event.preventDefault();
        }
        if (menuId && this.isMobile) {
            this.mobileClickedMenu = menuId;
        } else {
            this.mobileClickedMenu = null;
        }
    },

    toggleBurgerMenu() {
        this.isBurgerMenuOpen = !this.isBurgerMenuOpen;
        this.mobileClickedMenu = null;
        this.toggleBodyOverflow();
    },

    closeBurgerMenu() {
        this.isBurgerMenuOpen = false;
        this.mobileClickedMenu = null;
        this.toggleBodyOverflow();
    },

    toggleBodyOverflow() {
        document.body.classList.toggle(
            'overflow-y-hidden',
            this.isBurgerMenuOpen,
        );
        document.body.classList.toggle(
            'lg:overflow-y-visible',
            this.isBurgerMenuOpen,
        );
    },

    isSubmenuOpen(menuId: string) {
        if (!this.isMobile && this.desktopHoveredMenu == menuId) {
            return true;
        } else if (this.isMobile && this.mobileClickedMenu == menuId) {
            return true;
        }
        return false;
    },

    checkWindowSize() {
        this.isMobile = document.body.clientWidth < 1024 ? true : false;
        if (!this.isMobile) {
            this.closeBurgerMenu();
        }
        clearInterval(this.checkWindowSizeTimer);
    },

    calcAffix() {
        this.isAffixed = window.scrollY > this.threshold;
    },

    init() {
        window.addEventListener('resize', throttle(this.calcAffix.bind(this)));
        window.addEventListener('scroll', throttle(this.calcAffix.bind(this)));

        this.checkWindowSize();
        window.onresize = () => {
            clearInterval(this.checkWindowSizeTimer);
            this.checkWindowSizeTimer = setInterval(
                () => this.checkWindowSize(),
                500,
            );
        };
    },
}));

export { Header };
