function getCssVar(varName: string) {
    return getComputedStyle(document.documentElement).getPropertyValue(varName);
}

function setCssVar(varName: string, value: string) {
    document.documentElement.style.setProperty(varName, value);
}

function getBreakpoint(name: string) {
    const val = getCssVar(`--breakpoint-${name}`).replace('px', '');
    return parseInt(val, 10);
}

function isWiderThanBreakpoint(name: string) {
    return window.innerWidth >= getBreakpoint(name);
}

export { getCssVar, setCssVar, getBreakpoint, isWiderThanBreakpoint };
