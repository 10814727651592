import { createComponent } from '@/helpers/alpine';
import { throttle } from 'lodash-es';

const StickyAddToCart = createComponent(() => ({
    threshold: 700,
    isAffixed: false,

    calcAffix() {
        this.isAffixed = window.scrollY > this.threshold;
    },

    init() {
        window.addEventListener('resize', throttle(this.calcAffix.bind(this)));
        window.addEventListener('scroll', throttle(this.calcAffix.bind(this)));
    },
}));

export { StickyAddToCart };
