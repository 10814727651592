import { createComponent } from '@/helpers/alpine';
import { DataLayer } from '@/services/datalayer';

declare let psemailsubscription_subscription: string;

export default createComponent(() => ({
    email: '',
    message: '',
    loading: false,
    submitted: false,
    error: undefined as boolean | undefined,

    async submitData(event: SubmitEvent) {
        const form = event.target as HTMLFormElement;
        const formData = new FormData(form);

        this.message = '';
        this.error = undefined;
        this.loading = true;

        try {
            const response = await fetch(psemailsubscription_subscription, {
                method: 'POST',
                body: new URLSearchParams(formData as unknown as string),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json',
                },
            });

            const data = await response.json();

            this.email = data.value;
            this.message = data.msg;
            this.error = !!data.nw_error;
            this.submitted = true;

            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            !this.error
                ? DataLayer.push({ event: 'newsletter_subscription' })
                : '';
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    },
}));
