import prestashop from 'prestashop';
import { IGenericObject, TUpdateCartResponse } from '~types/common';
import { createComponent } from '@/helpers/alpine';
import wretch from 'wretch';
import FormDataAddon from 'wretch/addons/formData';

const CartActions = createComponent(() => ({
    controller: new AbortController(),

    getAbortSignal() {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.controller ? this.controller.abort() : null;
        this.controller = new AbortController();
        return this.controller.signal;
    },

    async makeQuery(url: string, action: string, body: IGenericObject = {}) {
        const signal = this.getAbortSignal();

        const data = await wretch(url, { signal })
            .addon(FormDataAddon)
            .headers({
                Accept: 'application/json',
            })
            .formData({
                ajax: '1',
                action: 'update',
                ...body,
            })
            .post()
            .json<TUpdateCartResponse>();

        prestashop.emit('updateCart', {
            reason: {
                idProduct: data.id_product,
                idProductAttribute: data.id_product_attribute,
                idCustomization: data.id_customization,
                linkAction: action,
                cart: data.cart,
            },
            resp: data,
        });
    },

    async more(url: string) {
        return this.makeQuery(url, 'increaseProductQuantity');
    },

    async less(url: string) {
        return this.makeQuery(url, 'decreaseProductQuantity');
    },

    async remove(url: string) {
        return this.makeQuery(url, 'delete-from-cart');
    },

    async removeVoucher(url: string) {
        return this.makeQuery(url, 'remove-voucher');
    },

    async update(url: string) {
        const inputEl = this.$el as HTMLInputElement;
        const value = parseInt(inputEl.value, 10);
        const baseValue = inputEl.getAttribute('value') ?? '';

        if (value < 0 || isNaN(value)) {
            inputEl.value = baseValue;
            return;
        }

        const qty = value - parseInt(baseValue, 10);
        if (qty === 0) {
            return;
        }

        return this.makeQuery(url, 'updateProductQuantityInCart', {
            qty: Math.abs(qty).toString(),
            op: qty > 0 ? 'up' : 'down',
        });
    },
}));

export { CartActions };
