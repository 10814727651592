import { createComponent } from '@/helpers/alpine';

const ProductAdditionalVars = createComponent(() => ({
    productTitle: '',
    productReference: '',
    hasEngraving: false,
    engravingText: null,
    engravingFont: null,
    engravingLabel: null,
}));

export { ProductAdditionalVars };
