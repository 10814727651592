import { createApp } from '@/helpers/alpine';
import { AlpineComponents } from 'alpinejs';

import '@/services/rgpd-cookies';
import '@/services/lazyload';

import { Sliders } from '@/components/sliders';
import { Toggler } from '@/components/toggler';
import { VideoPlayerYoutube } from '@/components/video-player-youtube';
import { VideoPlayerHtml } from '@/components/video-player-html';
import { Header } from '@/components/header';
import { StickyAddToCart } from '@/components/sticky-add-to-cart';
import { ProductAdditionalVars } from '@/components/product-additionnal-vars';

import fractofavorites from '@/modules/fractofavorites';
import ps_emailsubscription from '@/modules/ps_emailsubscription';
import ps_shoppingcart from '@/modules/ps_shoppingcart';
import itroom_accessories from '@/modules/itroom_accessories';

import collapse from '@alpinejs/collapse';
import intersect from '@alpinejs/intersect';
// import clipboard from "@/plugins/clipboard";
// import datalayer from "@/plugins/datalayer";
// import fitIframe from "@/plugins/fit-iframe";
// import obfuscatedLinks from '@/plugins/obfuscated-links';

import { CartActions } from '@/core/cart-actions';
import { Checkout } from '@/core/checkout';
import { CheckoutStep } from '@/core/checkout-step';
import { Customization } from '@/core/customization';

const Core: AlpineComponents = {
    // import ui core
    CartActions,
    Checkout,
    CheckoutStep,
    Customization,
};

const Components: AlpineComponents = {
    // import ui components
    Sliders,
    Toggler,
    VideoPlayerYoutube,
    VideoPlayerHtml,
    Header,
    StickyAddToCart,
    ProductAdditionalVars,
};

const Partials: AlpineComponents = {
    // import ui partials
};

const Modules: AlpineComponents = {
    fractofavorites,
    ps_emailsubscription,
    ps_shoppingcart,
    itroom_accessories,
};

createApp(
    {
        Core,
        Components,
        Partials,
        Modules,
    },
    [
        // Plugins :
        collapse,
        intersect,
        // clipboard,
        // datalayer,
        // fitIframe,
        // obfuscatedLinks,
    ],
);

// Available services startup
// DataLayer.init();
