// https://github.com/orestbida/cookieconsent
// https://playground.cookieconsent.orestbida.com/
import * as CookieConsent from 'vanilla-cookieconsent';

// import { merge } from 'lodash-es';
import { onReady } from '@/helpers/dom';

// @fractodo branchement analytics
// type TCookieReadonly = boolean;
// type TCookieEnabled = boolean;

// type TCookies = {
//     [key: string]: [TCookieReadonly, TCookieEnabled];
// };

// const makeCookieBlock = (
//     category: string,
//     readonly = false,
//     enabled = true,
// ) => ({
//     toggle: {
//         value: category,
//         readonly,
//         enabled,
//     },
// });

// const makeCookieBlocks = (cookies: TCookies) =>
//     Object.entries(cookies).map(([value, [readonly, enabled]]) =>
//         makeCookieBlock(value, readonly, enabled),
//     );

// const cookies: TCookies = {
//     functionality: [true, true],
//     // analytics: [false, true],
//     ad: [false, true],
// };

// const capitalize = (string: string) =>
//     string.charAt(0).toUpperCase() + string.slice(1);

// const initializeConsent = () => {
//     Object.entries(cookies).forEach(([c, [ro, e]]) => {
//         window.dataLayer.push({
//             event: `gdpr${ro && e ? 'Allow' : 'Disallow'}${capitalize(c)}`,
//             [`gdprAllow${capitalize(c)}`]: ro && e,
//         });
//     });
// };

const updateConsent = () => {
    // @fractodo branchement analytics
    // const { accepted_categories, rejected_categories } =
    //     cc.getUserPreferences();

    // accepted_categories.forEach(category => {
    //     window.dataLayer.push({
    //         event: `gdprAllow${capitalize(category)}`,
    //         [`gdprAllow${capitalize(category)}`]: true,
    //     });
    // });

    // rejected_categories.forEach(category => {
    //     window.dataLayer.push({
    //         event: `gdprDisallow${capitalize(category)}`,
    //         [`gdprAllow${capitalize(category)}`]: false,
    //     });
    // });

    window.dispatchEvent(
        new CustomEvent('CookieConsent:updateConsent', {
            detail: { cookieConsent: cc },
        }),
    );
};

const config: CookieConsent.CookieConsentConfig = {
    revision: 0,
    cookie: {
        name: 'newcom_cookie',
    },

    categories: {
        necessary: {
            enabled: true,
            readOnly: true,
        },
        analytics: {},
        // @fractodo branchement analytics
        // ad: {
        //     readOnly: false,
        //     enabled: true,
        // },
    },

    language: {
        default: 'fr',
        translations: {
            fr: {
                consentModal: {
                    title: 'Cookies',
                    description:
                        "Ce site Web utilise ses propres cookies et ceux de tiers pour améliorer nos services et vous montrer des publicités liées à vos préférences en analysant vos habitudes de navigation.</br>Ces derniers ne seront activés qu'avec votre consentement.",
                    acceptAllBtn: 'Tout accepter',
                    acceptNecessaryBtn: 'Tout décliner',
                    showPreferencesBtn: 'Personnaliser les cookies',
                },
                preferencesModal: {
                    title: 'Préférences des Cookies',
                    acceptAllBtn: 'Tout accepter',
                    acceptNecessaryBtn: 'Tout refuser',
                    savePreferencesBtn: 'Sauvegarder',
                    closeIconLabel: 'Fermer',
                    sections: [
                        {
                            title: 'Utilisation des cookies',
                            description:
                                "J'utilise des cookies pour assurer les fonctionnalités de base du site web et pour améliorer votre expérience en ligne. Pour chaque catégorie, vous pouvez choisir d'accepter ou de refuser les cookies quand vous le souhaitez. Pour plus de détails concernant les cookies et autres données sensibles, veuillez lire la <a href='/page/mentions-legales/49' class='cc-link'>politique de confidentialité</a> dans son intégralité.",
                        },
                        {
                            title: 'Cookies fonctionnels',
                            description:
                                "Les cookies fonctionnels sont strictement nécessaires pour fournir les services de la boutique, ainsi que pour son bon fonctionnement, il n'est donc pas possible de refuser leur utilisation. Ils permettent à l'utilisateur de naviguer sur notre site web et d'utiliser les différentes options ou services qui y sont proposés.",
                            linkedCategory: 'necessary',
                        },
                        // @fractodo branchement analytics
                        // {
                        //     title: "Cookies d'analyse",
                        //     description:
                        //         "Collecter des informations sur la navigation de l'utilisateur dans la boutique, généralement de manière anonyme, bien que parfois elles permettent également d'identifier l'utilisateur de manière unique et sans équivoque afin d'obtenir des rapports sur les intérêts de l'utilisateur pour les produits ou services proposés par la boutique.",
                        //     linkedCategory: 'analytics',
                        // },
                        {
                            title: "Plus d'informations",
                            description:
                                'Pour toute question relative à notre politique en matière de cookies et à vos choix, <a href="/page/contact/100">veuillez nous contacter</a>',
                        },
                    ],
                },
            },
        },
    },

    guiOptions: {
        consentModal: {
            layout: 'cloud',
        },

        preferencesModal: {
            layout: 'bar',
            position: 'right',
        },
    },

    onConsent() {
        updateConsent();
        // @fractodo branchement analytics
        // window.dataLayer.push({ event: 'gdprInitialized' });
    },

    onChange() {
        updateConsent();
    },
};

const cc = CookieConsent;

onReady(() => {
    cc.run(config);

    // @fractodo branchement analytics
    // const { rejected_categories } = cc.getUserPreferences();
    // const mandatory_categories = Object.entries(cookies)
    //     .filter(([c, [ro]]) => ro)
    //     .map(([c]) => c);

    // if (mandatory_categories.some((r) => rejected_categories.includes(r))) {
    //     initializeConsent();
    // }
});
