import { createComponent } from '@/helpers/alpine';

type TPsFavoritesOpts = {
    url: string;
    id_product: string;
    id_product_attribute: string;
    action: string;
};

export default createComponent((opts: TPsFavoritesOpts) => ({
    controller: new AbortController(),
    url: opts.url,
    id_product: opts.id_product,
    id_product_attribute: opts.id_product_attribute,
    action: opts.action,

    async handleUpdateFavorite() {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.controller ? this.controller.abort() : null;
        this.controller = new AbortController();
        const { signal } = this.controller;

        const requestData = {
            id_product: this.id_product,
            id_product_attribute: this.id_product_attribute,
            action: this.action,
        };

        const req: RequestInit = {
            body: JSON.stringify(requestData),
            signal,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };

        const url = new URL(this.url, window.location.origin);
        const response = await fetch(url.toString(), req);
        const data = await response.json();

        if (data.is_favorite) {
            this.action = 'remove';
        } else {
            this.action = 'add';
            this.removePlot();
            this.updateCounter();
        }
    },

    updateCounter() {
        const favoriteCountElem = document.getElementById('favorite-count');
        if (favoriteCountElem) {
            let currentCount = parseInt(
                favoriteCountElem.textContent || '0',
                10,
            );
            currentCount -= 1;
            favoriteCountElem.textContent = currentCount.toString();
            if (currentCount === 0) {
                const event = new CustomEvent('favorites:empty', {
                    detail: { hasFavorites: false },
                });
                window.dispatchEvent(event);
            }
        }
    },

    removePlot() {
        const className = `js-favorite-product-${this.id_product}-${this.id_product_attribute}`;
        const favoriteProductElem = document.querySelector(`.${className}`);
        if (favoriteProductElem) {
            favoriteProductElem.remove();
        }
    },
}));
