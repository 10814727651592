import { createComponent } from '@/helpers/alpine';
import { cloneAttributes, createNode } from '@/helpers/dom';

type TVideoPlayerHtmlOps = {
    videoUrl?: string;
    videoType?: string;
    loop?: boolean;
    muted?: boolean;
    autoplay?: boolean;
    controls?: boolean;
};

const VideoPlayerHtml = createComponent((opts: TVideoPlayerHtmlOps) => ({
    videoUrl: opts.videoUrl,
    videoType: opts.videoType,
    loop: opts.loop ?? false,
    muted: opts.muted ?? true,
    controls: opts.controls ?? true,
    autoplay: opts.autoplay ?? false,
    player: null as null | HTMLVideoElement,
    isPlaying: false,

    get defaultCover() {
        return null;
    },

    async setup() {
        if (!this.videoUrl) {
            return;
        }

        this.player = createNode<HTMLVideoElement>(`
            <video
                x-data
                crossorigin
                disableremoteplayback
                playsinline
                preload="metadata"
            >
                <source src="${this.videoUrl}#t=0.1" type="${this.videoType}">
            </video>
        `);

        cloneAttributes(this.$refs.placeholder, this.player);

        if (this.autoplay) {
            this.player.setAttribute('x-intersect', '$el.play()');
        }

        if (this.loop) {
            this.player.setAttribute('loop', '');
        }

        if (this.muted) {
            this.player.muted = true;
        }

        if (this.controls) {
            this.player.setAttribute('controls', '');
        }

        this.player.addEventListener('play', () => {
            this.isPlaying = true;
        });

        this.$refs.placeholder.replaceWith(this.player);
    },

    pause() {
        this.player?.pause();
    },

    play() {
        this.player?.play();
    },
}));

export { VideoPlayerHtml };
