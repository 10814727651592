import { createComponent } from '@/helpers/alpine';

const Customization = createComponent(() => ({
    launched: false,

    init() {
        window.addEventListener(
            'customization:size-customization-change',
            (() => {
                this.handleCustomizationChange(0);
            }).bind(this),
        );

        window.addEventListener(
            'customization:size-customization-create',
            ((event: Event) => {
                const idCustomization = (event as CustomEvent).detail;
                this.handleCustomizationChange(idCustomization);
            }).bind(this),
        );
    },

    async handleCustomizationChange(idCustomization: number) {
        if (this.launched) return;

        if (idCustomization > 0) return;

        const form = this.$refs['form-customization'] as HTMLFormElement;
        const formData = new FormData(form);
        formData.append('ajax', '1');
        formData.append('submitCustomizedData', '1');

        let currentOption = this.$refs[
            'current-size-customization'
        ] as HTMLOptionElement;
        if (!currentOption) {
            const select = this.$refs[
                'size-customization-select'
            ] as HTMLSelectElement;
            if (select)
                currentOption = select.firstElementChild as HTMLOptionElement;
        }

        if (form && currentOption) {
            this.launched = true;
            const response = await fetch(form.action, {
                method: 'POST',
                body: new URLSearchParams(formData as unknown as string),
                headers: {
                    'Content-Type':
                        'application/x-www-form-urlencoded; charset=UTF-8',
                },
            });

            const body = await response.json();

            this.launched = false;

            document
                .querySelectorAll(
                    '.js-product-customization input, .js-product-customization select',
                )
                .forEach((element) => {
                    const input = <HTMLInputElement>element;
                    input.setAttribute('disabled', 'disabled');
                });

            document
                .querySelectorAll(
                    '.js-product-customization input, .js-product-customization select',
                )
                .forEach((element) => {
                    const input = <HTMLInputElement>element;
                    input.removeAttribute('disabled');
                });

            if ('success' in body && body.success) {
                const customizationInput =
                    document.querySelector<HTMLInputElement>(
                        '#add-to-cart-or-refresh input[name$="id_customization"]',
                    );
                if (customizationInput)
                    customizationInput.value = body.id_customization;

                this.$dispatch('update-product-size-customization');
            }
        }
    },
}));

export { Customization };
